<template>
  <!-- Start donate Area -->
  <section class="donate-area relative section-gap" id="donate">
    <div class="overlay overlay-bg"></div>
    <div class="container" v-if="!hasError && !showPaymentForm && !showThanks">
      <div class="row d-flex justify-content-end">
        <div class="col-lg-12 col-sm-12 pb-80 header-text">
          <h1>DONATE NOW</h1>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-6 contact-left">
          <div class="single-info">
            <h2>Your contribution will allow us to continue the fight against Portelli’s monstrosity in Sannat</h2>
            <p>The Planning Authority (PA) has recently approved the third and largest application (PA/02087/21) in Joseph Portelli’s massive project that will forever ruin the character of Sannat as well as severely threaten the Ta’ Ċenċ cliffs. The cliffs are situated only 300 meters away from his 125-apartment block with pools and 95 garages.</p>
            <p>In order to effectively challenge this decision we need to raise 5,000eur that will allow us to undertake different legal actions against this monstrosity.</p>
            <p>The PA’s approval of this development was a scandal involving multiple irregularities. The development was illegally split into three separate applications to evade mandatory studies and a holistic assessment. Then, the third and largest of these three applications was approved by a Planning Commission that went against the case officer’s advice of refusal and inexplicably threw the application into the lap of Martin Saliba’s Executive Council.</p>
            <p>Concurrently, illegal works took place from the site of the project to the edge of the cliffs, with very worrying effects on both the natural environment as well as public access to Ta’ Ċenċ.</p>
            <p>All of this is alarming and totally unacceptable. <a href="https://movimentgraffitti.org/en" target="_blank">Moviment Graffitti</a> is determined to keep fighting this greed that is rapidly eating away our quality of life and natural environment.</p>
            <p>Everyone’s contribution, big or small, matters a lot.</p>
            <p>
              The fight continues!
            </p>
          </div>
        </div>
        <div class="col-lg-6 contact-right">
          <donation-form
              v-on:hasError="handleDonationError"
              v-on:donationCreated="onDonationCreated"
          />
          <img src="img/payment-form-bg.png" class="img-fluid">
        </div>
      </div>
    </div>
    <div class="container" v-if="hasError">
      <errors :error-message="errorMessage"/>
    </div>
    <div class="container" v-if="showPaymentForm">
      <payment-form :details="donationDetails" v-on:paid="onPaid" v-on:paymentError="onPaymentError"/>
    </div>
    <div class="container" v-if="showThanks">
      <thanks-paid :details="paymentDetails"/>
    </div>
  </section>
  <!-- End donate Area -->
</template>

<script>
import DonationForm from "@/components/Donation/DonationForm";
import Errors from "@/components/Errors";
import PaymentForm from "@/components/Donation/PaymentForm";
import ThanksPaid from "@/components/ThanksPaid";
export default {
  name: "Donation",
  components: {ThanksPaid, PaymentForm, Errors, DonationForm},
  data() {
    return {
      hasError: false,
      showPaymentForm: false,
      showThanks: false,
      donationDetails: null,
      errorMessage: null,
      paymentDetails: null
    }
  },
  methods: {
    handleDonationError(error) {
      this.hasError = true
      this.errorMessage = error
    },
    onDonationCreated(response) {
      this.showPaymentForm = true
      this.donationDetails = response
    },
    onPaid(response) {
      this.showPaymentForm = false
      this.showThanks = true
      this.paymentDetails = response
    },
    onPaymentError(response) {
      this.hasError = true
      this.errorMessage = response
    }
  }
}
</script>

<style scoped>
h2 {
  color:#FFFFFF;
  margin-bottom: 0.8em;
}
img {
  margin-top: 1.5em;
}
p {
  font-size: 17px;
  line-height: 1.5;
}
</style>
